import React from 'react';

const DefaultFooter = () => {
  return (
    <footer className="Footer">
      Сервис для откликов экспедиторов ГК Ресанта
    </footer>
  );
};

export default DefaultFooter;
