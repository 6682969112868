import './index.css';
import React from 'react';

import DefaultLayout from '../../components/defaultLayout/DefaultLayout';
import Instruction from '../../images/instruction_new.pdf';

function InstructionPage() {
  const pageHeight = document.documentElement.scrollHeight;

  return (
    <>
      <DefaultLayout>
        <embed
          src={Instruction}
          type="application/pdf"
          width="100%"
          height={pageHeight - 70 + 'px'}
        />
      </DefaultLayout>
    </>
  );
}

export default InstructionPage;
