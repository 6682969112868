import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './index.css';
import { useHttp } from '../../hooks/http.hook';
import { useMessage } from '../../hooks/message.hook';
import { AuthContext } from '../../context/AuthContext';
import Layout from 'antd/lib/layout/layout';
import { useHistory } from 'react-router-dom';
import imgLogo from '../../images/logo.png';
import DefaultFooter from '../defaultLayout/DefaultFooter';

const LoginForm = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const message = useMessage();
  const { loading, error, request, clearError } = useHttp();
  const [form, setForm] = useState({
    email: null,
    password: null,
  });

  useEffect(() => {
    error && message(error);
    clearError();
  }, [error, message, clearError]);

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const loginHandler = async () => {
    try {
      const data = await request('api/auth/login', 'POST', { ...form });

      if (data.code === 401) {
        notification.error({
          message: `Error login: `,
          description: data.message,
          placement: 'top',
        });
      } else {
        auth.login(
          data.tokens.access.token,
          data.tokens.refresh.token,
          data.user.id,
          data.user.role
        );

        localStorage.setItem(
          'userfield',
          data.user.userfields ? data.user.userfields : 'id'
        );
        localStorage.setItem(
          'userfieldContainer',
          data.user.userfieldContainer ? data.user.userfieldContainer : 'id'
        );
        localStorage.setItem(
          'forwarderFields',
          data.user.forwarderFields ? data.user.forwarderFields : 'id'
        );
      }

      if (data) {
        history.push('/');
      }
    } catch (e) {
      console.log('error-loginHandler >>> ', e);
    }
  };

  return (
    <>
      <div className="Header">
        <Header>
          <Link className="logo" to={'/'}>
            <img alt="logo" width="120px" src={imgLogo} />
          </Link>
        </Header>
      </div>
      <Layout
        className="login-form-container"
        style={{ minHeight: 'calc(100vh - 127px)' }}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your Email!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="email"
              name="email"
              value={form.email}
              onChange={changeHandler}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                min: 6,
                message: 'The input is not valid (min 6 symbol)',
              },
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              name="password"
              value={form.password}
              onChange={changeHandler}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={form.email && form.password && loginHandler}
              disabled={loading}
              loading={loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Layout>
      <DefaultFooter />
    </>
  );
};

export default LoginForm;
