import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  LOGIN_ROUTE,
  MAIN_ROUTE,
  ORDERS_ROUTE,
  USER_OFFERS_ROUTE,
  PROFILE_ROUTE,
  INSTRUCTION_ROUTE,
} from './utils/consts';
import Auth from './pages/Auth';
import MainPage from './pages/mainPage/MainPage';
import OrdersPage from './pages/ordersPage/OrdersPage';
import UserOffersPage from './pages/userOffersPage/UserOffersPage';
import ProfilePage from './pages/profilePage/ProfilePage';
import InstructionPage from './pages/instructionPage/InstructionPage';

export const useRoutes = (isAuthenticated, role) => {
  if (isAuthenticated) {
    if (role === 'forwarder') {
      return (
        <Switch>
          <Route
            key={MAIN_ROUTE}
            path={MAIN_ROUTE}
            component={MainPage}
            exact
          />
          <Route
            key={ORDERS_ROUTE}
            path={ORDERS_ROUTE}
            component={OrdersPage}
            exact
          />
          <Route
            key={USER_OFFERS_ROUTE}
            path={USER_OFFERS_ROUTE}
            component={UserOffersPage}
            exact
          />
          <Route
            key={PROFILE_ROUTE}
            path={PROFILE_ROUTE}
            component={ProfilePage}
            exact
          />
          <Route
            key={INSTRUCTION_ROUTE}
            path={INSTRUCTION_ROUTE}
            component={InstructionPage}
            exact
          />
          <Redirect to={MAIN_ROUTE} />
        </Switch>
      );
    }

    return (
      <Switch>
        {authRoutes.map(({ path, Component }) => {
          return <Route key={path} path={path} component={Component} exact />;
        })}
        <Redirect to={MAIN_ROUTE} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route key={LOGIN_ROUTE} path={LOGIN_ROUTE} component={Auth} exact />
      {/* <Redirect to={LOGIN_ROUTE} /> */}
      {/* 
      Redirect убрал, так как в коде используется переход на новую вкладку (target="_blank").
      Если будет Redirect, то target="_blank" не сработает.
      */}
      <Route key={LOGIN_ROUTE} path="*" component={Auth} exact />
      <Route key={LOGIN_ROUTE} path={MAIN_ROUTE} component={Auth} exact />
    </Switch>
  );
};

export const authRoutes = [
  { path: MAIN_ROUTE, Component: MainPage },
  { path: ORDERS_ROUTE, Component: OrdersPage },
  { path: USER_OFFERS_ROUTE, Component: UserOffersPage },
  { path: PROFILE_ROUTE, Component: ProfilePage },
  { path: INSTRUCTION_ROUTE, Component: InstructionPage },
];
