import React, { useState } from 'react';
import { Form, Button, Modal, Input, Divider, message } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { useHttp } from '../../hooks/http.hook';
import './index.css';

const ModalAgent = ({
  showModal,
  fetchDataTable,
  copyData,
  fetchDataSelect,
  forwId,
}) => {
  const { request } = useHttp();

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [countPerson, setCountPerson] = useState([1]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  const onFinishModal = async (values) => {
    setIsModalVisible(false);
    showModal(false);
    let agentContactsFormat = [];

    countPerson.map((e, index) => {
      let number = index + 1;

      return (agentContactsFormat = [
        ...agentContactsFormat,
        {
          contact: values[`contact_${number}`],
          phone: values[`phone_${number}`],
          mobile: values[`mobile_${number}`],
          email: values[`email_${number}`],
          // contactID: values[`contactID_${number}`],
        },
      ]);
    });

    try {
      let resData = null;
      // await request(process.env.REACT_APP_CHECK_TOKEN);

      if (copyData) {
        resData = await request(`api/agent/${copyData.agentId}`, 'PATCH', {
          name: values.name,
        });
      } else {
        resData = await request(`api/agent`, 'POST', {
          name: values.name,
          forwarderId: forwId,
        });
      }

      if (resData) {
        agentContactsFormat.map(async (item) => {
          updateAgentContacts(
            resData.id,
            item.contact,
            item.phone,
            item.mobile,
            item.email,
            copyData?.id
          );
        });
      }
    } catch (error) {
      console.log('ERROR - onFinishModal - agent>>>', error);
      if (error.message === 'Validation error') {
        message.info('ОШИБКА создания! Такой агент уже существует.');
      }
    }
  };

  const updateAgentContacts = async (
    agentId,
    contact,
    phone,
    mobile,
    email,
    contactID
  ) => {
    //! убрать на сервере обязательную отправку name для agent-contact
    try {
      // await request(process.env.REACT_APP_CHECK_TOKEN);

      if (copyData && contactID) {
        await request(`api/agent-contact/${contactID}`, 'PATCH', {
          agentId,
          contact,
          phone,
          mobile,
          email,
        });
      } else {
        await request(`api/agent-contact`, 'POST', {
          agentId,
          contact,
          phone,
          mobile,
          email,
          forwarderId: forwId,
        });
      }

      if (fetchDataTable) {
        await fetchDataTable();
      }

      if (fetchDataSelect) {
        await fetchDataSelect();
      }
    } catch (error) {
      console.log('ERROR - updateAgentContacts>>>', error);
      if (
        error.message === 'Validation error' ||
        error.message === 'Email or name already taken'
      ) {
        message.info(
          `ОШИБКА при создании ${contact}! Такие имя или email уже существуют.`
        );
      }
      // fetchDataTable();
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    showModal(false);
  };

  const changeInput = (e, nameInput) => {
    //запрещаем к записи только пробелы
    if (e.target.value.match(/^[ ]+$/)) {
      form.setFieldsValue({ [nameInput]: null });
    }
  };

  const addPerson = () => {
    const lastNumber = countPerson[countPerson.length - 1];
    setCountPerson([...countPerson, lastNumber + 1]);
  };

  const delPerson = async (e, id, name) => {
    // if (id) {
    //   window.confirm(`Удалить ${name}?`);
    //   try {
    //     await request(`api/agent-contact/${id}`, 'DELETE');
    //     await message.info(`${name} удален.`);
    //     setCountPerson(countPerson.filter((item) => item !== e));
    //   } catch (error) {
    //     console.log('ERROR - delPerson>>>', error);
    //     message.error(`Ошибка удаления!`);
    //   }
    // } else {
    setCountPerson(countPerson.filter((item) => item !== e));
    // }
  };

  // useEffect(() => {
  //   if (copyData && copyData.agent_contacts.length > 1) {
  //     let copyCount = [];
  //     copyData.agent_contacts.forEach((e, index) => {
  //       copyCount = [...copyCount, index + 1];
  //     });
  //     setCountPerson(copyCount);
  //   }

  //   if (copyData) {
  //     let newFormatAgents_contacts = {};
  //     copyData.agent_contacts.map((e, index) => {
  //       newFormatAgents_contacts[`contact_${index + 1}`] =
  //         copyData.agent_contacts[index]?.contact;
  //       newFormatAgents_contacts[`phone_${index + 1}`] =
  //         copyData.agent_contacts[index]?.phone;
  //       newFormatAgents_contacts[`mobile_${index + 1}`] =
  //         copyData.agent_contacts[index]?.mobile;
  //       newFormatAgents_contacts[`email_${index + 1}`] =
  //         copyData.agent_contacts[index]?.email;
  //       newFormatAgents_contacts[`contactID_${index + 1}`] =
  //         copyData.agent_contacts[index]?.id;
  //     });

  //     setAgentContacts(newFormatAgents_contacts);
  //   }
  // }, [copyData]);

  // useEffect(() => {
  //   if (countPerson.length === copyData?.agent_contacts.length) {
  //     setIsRender(true);
  //   }
  // });

  return (
    <>
      {
        /* isRender && */ <>
          <Modal
            title={copyData ? 'Редактирование агента' : 'Добавление агента'}
            open={isModalVisible}
            footer={[]}
            width={700}
            onCancel={handleModalCancel}
            maskClosable={false}
          >
            <Form
              {...layout}
              form={form}
              name="modalForm"
              onFinish={onFinishModal}
              initialValues={{
                name: copyData?.agentName,
                contact_1: copyData?.contact,
                email_1: copyData?.email,
                phone_1: copyData?.phone,
                mobile_1: copyData?.mobile,
              }}
            >
              <Form.Item
                className="Modal__item"
                name="name"
                label="Название"
                rules={[
                  {
                    required: true,
                    message: 'Поле не может быть пустым',
                  },
                ]}
              >
                <Input
                  placeholder="впишите данные"
                  onChange={(e) => changeInput(e, 'name')}
                />
              </Form.Item>
              <Form.Item
                className="Modal__item"
                name="contact"
                label="Контактное лицо"
              >
                {countPerson.map((e) => {
                  return (
                    <div key={e}>
                      {!copyData && (
                        <PlusCircleOutlined
                          className="iconPerson__plus"
                          onClick={addPerson}
                        />
                      )}
                      {countPerson.length > 1 && (
                        <MinusCircleOutlined
                          className="iconPerson__minus"
                          onClick={() =>
                            delPerson(
                              e
                              // agentContacts[`contactID_${e}`],
                              // agentContacts[`contact_${e}`]
                            )
                          }
                        />
                      )}
                      <div className="wrapperItems">
                        <Form.Item
                          className="Modal__item"
                          name={`contact_${e}`}
                          label="ФИО   "
                          rules={[
                            {
                              required: true,
                              message: 'Поле не может быть пустым',
                            },
                          ]}
                        >
                          <Input
                            placeholder="впишите ФИО"
                            onChange={(event) =>
                              changeInput(event, `personName_${e}`)
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          className="Modal__item"
                          name={`email_${e}`}
                          label="Почта "
                          rules={[
                            { type: 'email' },
                            {
                              required: true,
                              message: 'Поле не может быть пустым',
                            },
                          ]}
                        >
                          <Input
                            placeholder="впишите email"
                            onChange={(event) =>
                              changeInput(event, `email_${e}`)
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          className="Modal__item"
                          name={`phone_${e}`}
                          label="Телефон"
                          rules={[
                            {
                              required: true,
                              message: 'Поле не может быть пустым',
                            },
                            {
                              //! - уточнить формат телефона
                              //  pattern:'[0-9]{3}-[0-9]{3}-[0-9]{4}',
                              // message: 'номер должен соответсвовать формату  ........',
                            },
                          ]}
                        >
                          <Input
                            placeholder="впишите номер"
                            onChange={(event) =>
                              changeInput(event, `phone_${e}`)
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          className="Modal__item"
                          name={`mobile_${e}`}
                          label="   Моб. тел"
                          rules={[
                            {
                              //! - уточнить формат телефона
                              //  pattern:'[0-9]{3}-[0-9]{3}-[0-9]{4}',
                              // message: 'номер должен соответсвовать формату  ........',
                            },
                          ]}
                        >
                          <Input
                            placeholder="впишите номер"
                            onChange={(event) =>
                              changeInput(event, `mobile_${e}`)
                            }
                          />
                        </Form.Item>
                        {/* <Form.Item name={`contactID_${e}`}>
                          <Input type="hidden" />
                        </Form.Item> */}
                      </div>
                    </div>
                  );
                })}
              </Form.Item>
              <Divider style={{ margin: '20px 0' }} />
              <div className="Modal__btnsWrapper">
                <Form.Item>
                  <Button
                    className="Modal__btnSubmit"
                    type="primary"
                    htmlType="submit"
                  >
                    {copyData ? 'Сохранить изменения' : 'Добавить агента'}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </>
      }
    </>
  );
};

export default ModalAgent;
