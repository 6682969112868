import './index.css';
import React from 'react';

import DefaultLayout from '../../components/defaultLayout/DefaultLayout';
import UserOffersTable from '../../components/userOffersTable/UserOffersTable';

function UserOffersPage() {
  return (
    <>
      <DefaultLayout>
        <UserOffersTable />
      </DefaultLayout>
    </>
  );
}

export default UserOffersPage;
