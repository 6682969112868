import React from 'react';

import 'antd/dist/antd.css';
import './index.css';
import { Layout } from 'antd';

import DefaultHeader from './DefaultHeader';

const { Content } = Layout;

const DefaultLayout = (props) => {
  // const [collapse, setCollapse] = useState(true);

  // const onCollapse = () => {
  //   setCollapse(!collapse);
  // };

  return (
    <Layout>
      <DefaultHeader />

      <Content>{props.children}</Content>
    </Layout>
  );
};

export default DefaultLayout;
