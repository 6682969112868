import { useState, useCallback, useEffect } from 'react';

const storageName = 'userData';

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState(null);

  const login = useCallback(async (jwtToken, refJwtToken, id = 0, role) => {
    setToken(jwtToken);
    setUserId(id);
    setRole(role);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        userId: id,
        token: jwtToken,
        refToken: refJwtToken,
        role: role,
      })
    );
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(storageName);
    localStorage.removeItem('userfield');
    localStorage.removeItem('userFilename');
    localStorage.removeItem('userfieldContainer');
    localStorage.removeItem('forwarderFields');
    localStorage.removeItem('limitPagination');
    setToken(null);
    setUserId(null);
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));

    if (data && data.token) {
      login(data.token, data.refToken, data.userId, data.role);
    }
  }, [login]);

  return { login, logout, token, userId, role };
};
