import React, { useState } from 'react';
import { Form, Button, Modal, Input, Divider, message } from 'antd';

import { useHttp } from '../../hooks/http.hook';
import './index.css';

const ModalForwarderContacts = ({
  showModal,
  fetchDataTable,
  copyData,
  forwId,
}) => {
  const { request } = useHttp();

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(true);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  const onFinishModal = async (values) => {
    setIsModalVisible(false);
    showModal(false);

    // await request(process.env.REACT_APP_CHECK_TOKEN);

    try {
      let resData = null;
      if (copyData) {
        resData = await request(
          `api/forwarder-contact/${copyData.id}`,
          'PATCH',
          {
            ...values,
            forwarderId: forwId,
          }
        );
      } else {
        resData = await request(`api/forwarder-contact`, 'POST', {
          ...values,
          forwarderId: forwId,
        });
      }

      if (resData) {
        message.info('Данные записаны!');
        fetchDataTable();
      }
    } catch (error) {
      console.log('ERROR - onFinishModal - forwarderContacts>>>', error);
      if (
        error.message === 'Validation error' ||
        error.message === 'Email or name already taken'
      ) {
        message.info(
          `ОШИБКА при создании ${values.contact}! Такие имя или email уже существуют.`
        );
      }
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    showModal(false);
  };

  const changeInput = (e, nameInput) => {
    //запрещаем к записи только пробелы
    if (e.target.value.match(/^[ ]+$/)) {
      form.setFieldsValue({ [nameInput]: null });
    }
  };

  return (
    <Modal
      title={copyData ? 'Редактирование контакта' : 'Добавление контакта'}
      open={isModalVisible}
      footer={[]}
      width={700}
      onCancel={handleModalCancel}
      maskClosable={false}
    >
      <Form
        {...layout}
        form={form}
        name="modalForm"
        onFinish={onFinishModal}
        initialValues={{
          contact: copyData?.contact,
          position: copyData?.position,
          email: copyData?.email,
          phone: copyData?.phone,
          mobile: copyData?.mobile,
        }}
      >
        <Form.Item
          className="Modal__item"
          name="contact"
          label="ФИО"
          rules={[
            {
              required: true,
              message: 'Поле не может быть пустым',
            },
          ]}
        >
          <Input
            placeholder="впишите ФИО"
            disabled={copyData ? true : false}
            onChange={(event) => changeInput(event, 'contact')}
          />
        </Form.Item>
        <Form.Item className="Modal__item" name="position" label="Должность">
          <Input
            placeholder="впишите должность"
            onChange={(event) => changeInput(event, 'position')}
          />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="email"
          label="Почта "
          rules={[
            { type: 'email' },
            {
              required: true,
              message: 'Поле не может быть пустым',
            },
          ]}
        >
          <Input
            placeholder="впишите email"
            disabled={copyData ? true : false}
            onChange={(event) => changeInput(event, 'email')}
          />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="phone"
          label="Телефон"
          rules={[
            {
              required: true,
              message: 'Поле не может быть пустым',
            },
            {
              //! - уточнить формат телефона
              //  pattern:'[0-9]{3}-[0-9]{3}-[0-9]{4}',
              // message: 'номер должен соответсвовать формату  ........',
            },
          ]}
        >
          <Input
            placeholder="впишите номер"
            onChange={(event) => changeInput(event, 'phone')}
          />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="mobile"
          label="   Моб. тел"
          rules={[
            {
              //! - уточнить формат телефона
              //  pattern:'[0-9]{3}-[0-9]{3}-[0-9]{4}',
              // message: 'номер должен соответсвовать формату  ........',
            },
          ]}
        >
          <Input
            placeholder="впишите номер"
            onChange={(event) => changeInput(event, 'mobile')}
          />
        </Form.Item>

        <Divider style={{ margin: '20px 0' }} />
        <div className="Modal__btnsWrapper">
          <Form.Item>
            <Button
              className="Modal__btnSubmit"
              type="primary"
              htmlType="submit"
            >
              {copyData ? 'Сохранить изменения' : 'Добавить контакт'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalForwarderContacts;
