import { useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

export const useHttp = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (url, method = 'GET', body = null) => {
    const userData = localStorage.getItem('userData');
    let token = null;
    if (userData) {
      token = JSON.parse(userData).token;
    }
    let headers = { Authorization: `Bearer ${token}` };
    setLoading(true);
    let data = null;
    try {
      if (body) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }

      const response = await fetch(process.env.REACT_APP_API_URL + url, {
        method,
        body,
        headers,
      });

      data = response.status === 204 ? [] : await response.json();

      if (data.code === 401 && userData) {
        const refToken = JSON.parse(userData).refToken;
        const userId = JSON.parse(userData).userId;
        const role = JSON.parse(userData).role;

        if (
          data.message === 'Error: Token not found' ||
          data.message === 'TokenExpiredError: jwt expired'
        ) {
          auth.logout();
          history.push('/login');
        } else {
          try {
            const respData = await request('api/auth/refresh-tokens', 'POST', {
              refreshToken: refToken,
            });

            localStorage.setItem(
              'userData',
              JSON.stringify({
                token: respData.tokens.access.token,
                refToken: respData.tokens.refresh.token,
                userId,
                role,
              })
            );
            // window.location.reload();
            /* --- повторный запрос при смене токена --- */
            let newResponse = null;
            if (method !== 'GET') {
              newResponse = await fetch(process.env.REACT_APP_API_URL + url, {
                method,
                headers: {
                  Authorization: `Bearer ${respData.tokens.access.token}`,
                  'Content-Type': 'application/json',
                },
                body,
              });
            } else {
              newResponse = await fetch(process.env.REACT_APP_API_URL + url, {
                method,
                headers: {
                  Authorization: `Bearer ${respData.tokens.access.token}`,
                },
              });
            }

            data =
              (await newResponse.status) === 204
                ? []
                : await newResponse.json();
            /* --- END повторный запрос при смене токена --- */
          } catch (error) {
            console.log('ERROR !!! >>>', error);
          }
        }
      }
      setLoading(false);
      return data;
    } catch (e) {
      setError(e.message);
      setLoading(false);
      throw e;
    }
  }, []);

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError, setLoading };
};
