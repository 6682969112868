import React from 'react';
import { Link } from 'react-router-dom';
import imgLogo from '../../images/logo.png';
import { Header } from 'antd/lib/layout/layout';
import NavBar from '../navBar/NavBar';

const DefaultHeader = () => {
  return (
    <div className="DefaultHeader__wrapper">
      <div className="Header">
        <Header>
          <Link className="logo" to={'/'}>
            <img alt="logo" width="120px" src={imgLogo} />
          </Link>
          <NavBar />
        </Header>
      </div>
    </div>
  );
};

export default DefaultHeader;
