import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHttp } from '../../hooks/http.hook';

export default function DownloadButton({ record, docType }) {
  const { request } = useHttp();
  const [fileDownloading, setFileDownloading] = useState(false); // состояние загрузки

  const downloadHandler = async (offerId, docType) => {
    setFileDownloading(true); // "включение" состояния загрузки файла (для показа спиннера)
    /* 
    хранение информации о категориях товаров без файлов во время попытки загрузки 
    архива доп.док-в, для дальнейшего её отображения в модальном окне  
    */
    const noFileCategoryNames = [];

    try {
      // в ответ на первый запрос приходит информация о том, какие файлы будут загружены,
      // а каких не хватает
      const resultStage1 = await request(
        `api/product/dwnld/${offerId}/${docType}/1`
      );

      if (
        resultStage1.message === 'success' ||
        resultStage1.message === 'warning'
      ) {
        // заполнение массива с категориями продуктов без файлов
        resultStage1.productsWithFiles.forEach((product) => {
          if (!product.additDocsFiles.length) {
            noFileCategoryNames.push(product.categoryName);
          }
        });

        // в случае, если message в первом запросе равен 'success' (у всех товаров есть файлы)
        // или 'warning' (у части товаров нет файлов), на сервер отправляется второй запрос (ответ - архив)
        const resultStage2 = await fetch(
          `${process.env.REACT_APP_API_URL}api/product/dwnld/${offerId}/${docType}/2`
        );

        // если ответ с архивом успешен
        if (resultStage2.ok) {
          const blobData = await resultStage2.blob();
          const url = window.URL.createObjectURL(blobData);
          const a = document.createElement('a');
          const filename = `${docType}_offer_${offerId}.zip`;
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }

        // случай, когда все файлы есть в БД (и ни одно название категории не попало в noFileCategoryNames)
        if (noFileCategoryNames.length === 0) {
          Modal.success({
            title: 'Успешно',
            content: 'Все файлы загружены',
          });
        } else if (noFileCategoryNames.length > 0) {
          // случай, когда не все файлы есть в БД (и некоторые названия категорий попали в noFileCategoryNames)
          Modal.warning({
            title: 'Неполная загрузка',
            content: (
              <div>
                <p>Отсутствуют доп. документы категорий:</p>
                <ul>
                  {noFileCategoryNames.map((item) => (
                    <li key={`key-${item}`}>{item}</li>
                  ))}
                </ul>
                <p>Файлы товаров других категорий загружены успешно.</p>
              </div>
            ),
          });
        }
      } else {
        // случай, когда по данному Offer у товаров отсутствуют доп.документы выбранного типа
        Modal.error({
          title: 'Ошибка',
          content:
            'В продуктах данного оффера отсутствуют файлы доп.документов выбранного типа.',
        });
      }
      setTimeout(() => {
        setFileDownloading(false);
      }, 500);
    } catch (error) {
      console.log('Ошибка при запросе к серверу: ', error);
      Modal.error({
        title: 'Ошибка при запросе к серверу:',
      });
    }
  };
  return (
    <Button
      key={`${record?.id}-btn`}
      type="default"
      icon={fileDownloading ? <LoadingOutlined spin /> : <DownloadOutlined />}
      size="small"
      onClick={() => downloadHandler(record?.id, docType)}
      className="download-button"
    />
  );
}
