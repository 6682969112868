import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import './index.css';

import 'antd/dist/antd.css';
import {
  LogoutOutlined,
  ProjectOutlined,
  UserOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { AuthContext } from '../../context/AuthContext';

const NavBar = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
    history.push('/login');
  };

  const items = [
    {
      label: (
        <a href="/" onClick={logoutHandler}>
          Выйти
        </a>
      ),
      key: '1_exit',
      icon: <LogoutOutlined />,
    },
    {
      label: <NavLink to="/orders">Заявки</NavLink>,
      key: 'toOrdersPage',
      icon: <ProjectOutlined />,
    },
    {
      label: <NavLink to="/profile">Личный кабинет</NavLink>,
      key: 'toProfilePage',
      icon: <UserOutlined />,
    },
    {
      label: <NavLink to="/instruction">Инструкция</NavLink>,
      key: 'toInstructionPage',
      icon: <QuestionCircleOutlined />,
    },
  ];

  return <Menu theme="dark" mode="horizontal" items={items} />;
};

export default NavBar;
