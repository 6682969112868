import DirectoryStore from '../store/DirectoryStore';
import { Button, Input, Checkbox, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const getFiltersInColumnColor = (
  data,
  setSelectedKeys,
  selectedKeys,
  confirm,
  nameColumn,
  nameInStore,
  columnDate,
  dateRange,
  notRangeInFilter,
  handlerColorFilter,
  handlerColorDateFilter,
  arrNames // используется, когда у данных в колонке глубокая вложенность
) => {
  let rowValues = [];

  if (columnDate) {
    rowValues = [];
  } else if (nameInStore) {
    if (nameInStore === 'agentContacts') {
      rowValues = DirectoryStore[nameInStore]?.map((item) => {
        return { label: item.contact, value: item.contact };
      });
    } else {
      rowValues = DirectoryStore[nameInStore]?.map((item) => {
        return { label: item.name, value: item.name };
      });
    }
  } else {
    if (arrNames) {
      rowValues = [
        ...new Set(
          arrNames?.map((name) => {
            if (name !== null) {
              return name;
            } else {
              return 'нет значения';
            }
          })
        ),
      ].map((item) => {
        if (item === 'нет значения') {
          return { label: 'нет значения', value: '' };
        } else {
          return { label: item, value: item };
        }
      });
    } else {
      if (nameColumn === 'general') {
        rowValues = [
          { label: 'ген.груз', value: 'general' },
          { label: 'масло', value: 'oil' },
          { label: 'акб', value: 'batteries' },
        ];
      } else {
        rowValues = [
          ...new Set(
            data?.map((item) => {
              if (item[nameColumn] !== null) {
                return item[nameColumn];
              } else {
                return 'нет значения';
              }
            })
          ),
        ].map((item) => {
          if (item === 'нет значения') {
            return { label: 'нет значения', value: '' };
          } else {
            return { label: item, value: item };
          }
        });
      }
    }
  }

  const onChangeFilter = (checked) => {
    setSelectedKeys(checked);
  };

  const onCheckAll = (e) => {
    if (e.target.checked) {
      const allValues = rowValues.map((item) => item.value);
      setSelectedKeys(allValues);
    } else {
      setSelectedKeys();
    }
  };

  const searchFilter = (e) => {
    const searchValues = rowValues.reduce((acc, item) => {
      if (
        e.target.value &&
        item.label
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase())
      ) {
        acc.push(item.value);
      }
      return acc;
    }, []);
    setSelectedKeys(searchValues);
  };

  const getFilter = () => {
    if (handlerColorDateFilter) {
      handlerColorDateFilter({
        [`${nameColumn}From`]: selectedKeys
          ? selectedKeys[0]?.format('YYYY-MM-DD')
          : null,
        [`${nameColumn}To`]: selectedKeys
          ? selectedKeys[1]?.format('YYYY-MM-DD')
          : null,
      });
    } else if (nameColumn === 'general') {
      let newObj = {};
      if (selectedKeys.length === 0) {
        newObj = { general: [], oil: [], batteries: [] };
      } else {
        if (selectedKeys.includes('general')) {
          newObj.general = [true];
        } else {
          newObj.general = [];
        }

        if (selectedKeys.includes('oil')) {
          newObj.oil = [true];
        } else {
          newObj.oil = [];
        }

        if (selectedKeys.includes('batteries')) {
          newObj.batteries = [true];
        } else {
          newObj.batteries = [];
        }
      }
      handlerColorFilter(newObj);
    } else {
      const additKeys = [];
      if (nameColumn === 'destination') {
        /* 
        при формировании отклика ключ "Конечный пункт" формируется
        в расширенном варианте (с пояснением, типа "...(до склада в городе Чехов)").
        но в dropdown-фильтре отображаются только стандартные варианты из БД
        ("МСК", "НСК" и тд). чтобы соотнести их между собой при поиске, в selectedKeys
        добавляется также соответствующий расширенный вариант
        */
        if (selectedKeys.includes('МСК')) {
          additKeys.push('МСК (до склада в городе Чехов)');
        }
        if (selectedKeys.includes('НСК')) {
          additKeys.push('НСК (до склада в село Толмачево)');
        }
        if (selectedKeys.includes('КЗ(Kazakhstan)')) {
          additKeys.push('КЗ (до станции Медеу/Алматы без автовывоза)');
        }
        if (selectedKeys.includes('Калининград(Kaliningrad)')) {
          additKeys.push(
            'Калининград (до склада в Калининграде, ул. Ялтинская)'
          );
        }
      }

      handlerColorFilter({ [nameColumn]: [...selectedKeys, ...additKeys] });
    }

    confirm();
  };

  const resetFilter = () => {
    setSelectedKeys([]);
  };

  return (
    <div className="filterInColumn__wrapper">
      {columnDate && !notRangeInFilter ? (
        <div className="RangePickerWrapper">
          <RangePicker
            format={'DD-MM-YYYY'}
            className="RangePicker"
            onChange={onChangeFilter}
          />
          <div className="filterInColumn__btns">
            <Button
              className="filterInColumn__btn"
              type="primary"
              size="small"
              onClick={getFilter}
            >
              Ok
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Input
            className="filterInColumn__input"
            placeholder="Поиск в фильтре"
            onChange={searchFilter}
          ></Input>
          <Checkbox className="CheckAllChange" onChange={onCheckAll}>
            Check all
          </Checkbox>
          <Checkbox.Group
            className="filterInColumn__checkboxGroup"
            options={rowValues}
            value={selectedKeys}
            onChange={onChangeFilter}
          />
          <div className="filterInColumn__btns">
            <Button
              className="filterInColumn__btn"
              type="link"
              danger
              size="small"
              onClick={resetFilter}
            >
              Reset
            </Button>
            <Button
              className="filterInColumn__btn"
              type="primary"
              size="small"
              onClick={getFilter}
            >
              Ok
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default getFiltersInColumnColor;
