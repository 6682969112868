import { makeAutoObservable } from 'mobx';

class SelectedColorFilterStore {
  values = null;

  constructor() {
    makeAutoObservable(this);
  }

  getValues() {
    return this.values;
  }

  setValues(arr) {
    this.values = arr;
  }
}

export default new SelectedColorFilterStore();
