import React from 'react';
import DefaultLayout from '../../components/defaultLayout/DefaultLayout';

function MainPage() {
  return (
    <>
      <DefaultLayout></DefaultLayout>
    </>
  );
}

export default MainPage;
