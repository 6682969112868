import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
// import DefaultLayout from './components/defaultLayout/DefaultLayout';
import { AuthContext } from './context/AuthContext';
import { UploaderContext } from './context/UploaderContext';
import { DataContext } from './context/DataContext';

import { useAuth } from './hooks/auth.hook';
import { useRoutes } from './routes';

const App = () => {
  const [contextUploader, setContextUploader] = useState(false); // для отслеживания изменений
  const [contextData, setContextData] = useState(null);
  const { token, userId, role, login, logout } = useAuth();
  const isAuth = !!token;

  const routes = useRoutes(isAuth, role);

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        userId,
        isAuth,
        role,
      }}
    >
      <UploaderContext.Provider value={[contextUploader, setContextUploader]}>
        <DataContext.Provider value={[contextData, setContextData]}>
          <BrowserRouter>{routes}</BrowserRouter>
        </DataContext.Provider>
      </UploaderContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
