import React, { useState, useEffect } from 'react';
import { Table, Button, message, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import './index.css';
import getFiltersInColumn from '../../utils/filteredColumns';
import { useHttp } from '../../hooks/http.hook';
import ModalAgent from '../modalAgent/ModalAgent';

const AgentsTable = ({ agentsData, fetchDataTable, forwId }) => {
  const { loading, request } = useHttp();
  const [data, setData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allRowData, setAllRowData] = useState();

  const columns = [
    {
      title: 'Действие',
      dataIndex: 'actionBtns',
      key: 'actionBtns',
      width: 100,
      render: (text, record) => {
        return (
          <div className="AgentsTable__wrapperBtns">
            <Button
              className="AgentsTable__btn"
              onClick={() => {
                setAllRowData(record);
                setIsModalVisible(true);
              }}
              icon={
                <EditOutlined
                  style={{
                    fontSize: '16px',
                    color: '#1890ff',
                  }}
                />
              }
            />
            <Button
              className="AgentsTable__btn"
              onClick={() => {
                openNotification(record.agentId, record.agentName, record.id);
                // handlerDeleteAgent(record.agentId, record.agentName);
              }}
              icon={
                <DeleteOutlined
                  style={{
                    fontSize: '16px',
                    cursor: 'pointer',
                    color: 'red',
                  }}
                />
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Агент',
      dataIndex: 'agentName',
      key: 'agentName',
      className: 'AgentsTable__column',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'agentName'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.agentName) {
          return !record.agentName;
        } else {
          return record.agentName?.includes(value);
        }
      },
      sorter: (a, b) => a.agentName?.localeCompare(b.agentName),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Контактное лицо',
      dataIndex: 'contact',
      key: 'contact',
      className: 'AgentsTable__column',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'contact'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.contact) {
          return !record.contact;
        } else {
          return record.contact?.includes(value);
        }
      },
      sorter: (a, b) => a.contact?.localeCompare(b.contact),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      className: 'AgentsTable__column',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'phone'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.phone) {
          return !record.phone;
        } else {
          return record.phone?.includes(value);
        }
      },
      sorter: (a, b) => a.phone?.localeCompare(b.phone),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Мобильный телефон',
      dataIndex: 'mobile',
      key: 'mobile',
      className: 'AgentsTable__column',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'mobile'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.mobile) {
          return !record.mobile;
        } else {
          return record.mobile?.includes(value);
        }
      },
      sorter: (a, b) => a.mobile?.localeCompare(b.mobile),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      key: 'email',
      className: 'AgentsTable__column',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'email'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.email) {
          return !record.email;
        } else {
          return record.email?.includes(value);
        }
      },
      sorter: (a, b) => a.email?.localeCompare(b.email),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
  ];

  const showModal = (value) => {
    setIsModalVisible(value);
    if (!value) {
      setAllRowData(null);
    }
  };

  useEffect(() => {
    if (agentsData) {
      setData(agentsData);
    }
  }, [agentsData]);

  const openNotification = (id, name, agentContactId) => {
    const key = id;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          handlerDeleteAgent(agentContactId, name);
          notification.close(key);
        }}
      >
        Удалить
      </Button>
    );
    notification.info({
      message: 'Подтвердите удаление',
      description: `Вы действительно хотите удалить ${name}?`,
      btn,
      key,
      placement: 'top',
    });
  };

  const handlerDeleteAgent = async (id, name) => {
    try {
      await request(`api/agent-contact/${id}`, 'DELETE');
      // await message.info(`Удаление ${name}...`);
      await fetchDataTable();
    } catch (error) {
      console.log('ERROR - handlerDeleteAgent >>>', error);
      message.error(`Ошибка удаления!`);
    }
  };

  return (
    <div className="AgentsTableWrapper">
      <div className="AgentsTable__header">Список агентов</div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        scroll={{ x: true, y: 'calc(100vh - 340px)' }}
      />
      <div className="AgentsTable__footer">
        <Button onClick={() => setIsModalVisible(true)}>Добавить агента</Button>
      </div>
      {isModalVisible && (
        <ModalAgent
          showModal={showModal}
          fetchDataTable={fetchDataTable}
          copyData={allRowData}
          forwId={forwId}
        />
      )}
    </div>
  );
};

export default AgentsTable;
