import './index.css';
import React from 'react';

import DefaultLayout from '../../components/defaultLayout/DefaultLayout';
import OrdersTable from '../../components/ordersTable/OrdersTable';

function OrdersPage() {
  return (
    <>
      <DefaultLayout>
        <OrdersTable />
      </DefaultLayout>
    </>
  );
}

export default OrdersPage;
