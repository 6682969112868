//todo константы для Select-списков модального окна "Отправить предложение" в таблице "Заявки".

/* 
"Порт/станция отправки"

для методов "ДВ", "СПБ", "Калининград", будет использован список по ключу "sea",
для методов "ЖД" или "КЗ" -- список по ключу "railway".
(для метода Авто списка нет, там текстовый Input)
*/
export const sendingStationLists = {
  sea: [
    'Chongqing',
    'Nansha',
    'Ningbo',
    'Qingdao',
    'Shanghai',
    'Xiaolan',
    'Fuzhou',
    'Другое (обязательно указать в комментарии)',
  ],
  railway: [
    'Changsha',
    'Chengdu',
    'Dalian',
    'Deyang',
    'Guangzhou',
    'Harbin',
    'Jinhua',
    'Lianyungang',
    'Nanchang',
    'Nanjing',
    'Shenyang',
    'Shenzhen',
    'Suzhou',
    'Taicang',
    'Tianjin',
    'Urumchi',
    'Wuxi',
    'Xi’An',
    'Yingtan',
    'Yiwu',
    'Yuzui',
    'Другое (обязательно указать в комментарии)',
  ],
};

// "Условия поставки"
export const termsOfDeliveryList = ['FOB', 'FOR'];

// "Терминал назначения", в зависимости от метода
export const terminalLists = {
  'ДВ (by sea)': [
    'ВМКТ',
    'ВМПП',
    'ВСК',
    'ВМТП',
    'Астафьева',
    'Другое (обязательно указать в комментарии)',
  ],
  'СПБ (by sea)': [
    'Бронка',
    'КТСП',
    'Петролеспорт',
    'ПКТ',
    'МРП',
    'Усть-Луга',
    'Другое (обязательно указать в комментарии)',
  ],
  'Калининград (by sea)': [
    'Балтийск',
    'Калининград',
    'ДВ',
    'Другое (обязательно указать в комментарии)',
  ],
  'ЖД (direct railway road)': {
    НСК: [
      'Иня-Восточная',
      'Сеятель',
      'Клещиха',
      'Чик',
      'Новосибирск-Восточный',
      'Чемской',
      'Базаиха',
    ],
    остальные: [
      'Белый Раст',
      'Ворсино',
      'Купавна',
      'Селятино',
      'Ховрино',
      'Электроугли',
    ],
  },
  'КЗ (direct railway road)': ['Алматы', 'Медеу'],
};
